<template>
    <div class="o-layout o-layout--medium">
        <div class="container-fluid">
            <div class="row">
                <!-- Go back button -->
                <div class="col-12">
                    <recess-button
                        :title="BUTTON_TEXT.goBack"
                        icon="back"
                        variant="variant4"
                        class="qa-go-back-button"
                        url="/portfolio/import"
                    /> 

                    <title-text
                        :title="PROVIDER.Portfolio.EdudexFeed.ImportDetails.Title"
                        class="qa-edudex-import-details-title"
                    />
                </div>
            </div>

            <!-- Edudex Import List -->

            <edudex-import-details />

            <div class="row">
                <edudex-import-failures-list :set-selected-filters="localFilters" />
            </div>
        </div>
    </div>
</template>

<script>
import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

const TitleText = () => import('@/components/atoms/TitleText')
const EdudexImportDetails = () => import('@/components/organisms/Portfolio/Edudex/EdudexImportDetails')
const EdudexImportFailuresList = () => import('@/components/organisms/Portfolio/Edudex/EdudexImportFailuresList')

import filterMixin from '@/../../shared/mixins/filterMixin'

export default {
    name: 'EdudexImportDetailsPage',
    components: {
        TitleText,
        EdudexImportDetails,
        EdudexImportFailuresList
    },
    mixins: [filterMixin],
    data() {
        return {
            BUTTON_TEXT,
            PROVIDER,
            selectedFilters: {}
        }
    }
}
</script>
